@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/DMSans-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  src: local('DM Sans Italic'), url('./fonts/DMSans-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/DMSans-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/DMSans-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/DMSans-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/DMSans-BoldItalic.woff') format('woff');
  font-display: swap;
}
